import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`K Rend `}<em parentName="h1">{`(Kilwaughter Chemical Co Ltd)`}</em></h1>
    <p>{`Kilwaughter Chemical Co Ltd has been an established quarry and mineral processor since 1939.`}</p>
    <p>{`In the late 1980s the company began to focus on the design and manufacture of renders using calcium carbonate lime quarried on site. The render is marketed under the K Rend brand name and has since grown from a small, family-run firm into a market leader in the UK and Ireland supplying render to the construction industry.`}</p>
    <p>{`Manufacturing and production facilities are situated at headquarters in Larne, Northern Ireland, with manufacturing operations covering a combined area of over 50 acres, from which they produce in excess of 200,000 tons of material per annum.`}</p>
    <p>{`K Rend is the only render product in todays UK & Ireland market that has been awarded the prestigious Kitemark license to BS EN 998:2003. Kitemark schemes are voluntary, illustrating commitment to providing customers with the very best quality and service.`}</p>
    <p>{`Kilwaughter pride themselves in providing their customers with the very best quality products and service so they ensure a level of service unmatched by many others in the industry.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      